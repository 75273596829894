@import '../../CSS/base';

.container {
  position: relative;
  z-index: 22;
}
.footer {
  margin-top: 3rem;
  padding: 2rem 0 0.5rem;
  display: flex;
  flex-direction: column;

  &_section {
    display: flex;
    flex-direction: column;
    h2 {
      margin-bottom: 0.5rem;
    }
    P {
      margin: 0;
    }
  }

  &_pride {
    width: 2.7rem;
    height: 1.8rem;
  }
}

.footer > * + * {
  margin-top: 3.5rem;
}

.footer_nav {
  margin-top: 1rem;
}

.insta_icon {
  width: 4rem;
  height: 4rem;
  transition: opacity 0.3s;
  &:hover {
    
    opacity: 0.5;
  }
}

.nav {
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.5;
  }
}

.active {
  font-weight: bold;
}

.credit {
  padding: 2rem 0;
}

@media (min-width: $media_tablet) {
  .footer > * + * {
    margin-top: 0;
    margin-left: 2rem;
  }
  .footer {
    flex-direction: row;

    &_pride {
      margin-left: auto;
      margin-top: 0.2rem;
    }
  }
}
