@import '../../CSS/base';

.overview {
  --gap: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.cardContainer {
  --gap: 6rem;
}

.concierge {
  margin-bottom: 2rem;
}

.scroll {
  display: flex;
}

@media (min-width: $media_tablet) {
  .concierge {
    &_details {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &_header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      h2 {
        margin: 0;
      }
    }
  }
}

@media (min-width: $media_tablet_landscape) {
  .explore {
    &_header {
      flex-direction: row;
      justify-content: space-between;
      gap: 3rem;
      margin-bottom: 2rem;

      h2,
      p {
        margin: 0;
      }
    }
    &_scroll {
      font-size: 1.3rem;
      justify-content: center;
    }
  }
}
