@import '../../CSS/base';

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 70vmin;
  min-height: 15rem;
  margin: 0;
}

.inner_container {
  height: 100%;
  width: 100%;
  margin: auto;
}

.arrows {
  margin-top: 1rem;
  img {
    cursor: pointer;
    &[aria-disabled='true'] {
      opacity: 0.2;
    }

    &:hover {
      opacity: 0.4;
    }
  }
}

.content {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 100%;
  transition: all 0.3s;
}

.slideContainer {
  padding: 0 0.5rem;
  transition: all 0.3s;
}

.slide {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  object-fit: cover;
  flex-shrink: 0;
}

@media (min-width: $media_tablet_landscape) {
  .inner_container {
    width: 80%;
  }

  .content > * {
    opacity: 0.4;
  }

  .content {
    & .slideContainer:nth-child(2) {
      opacity: 1;
    }
  }
}
