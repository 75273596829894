@import '../../CSS/base';
@import '../../CSS/utility.scss';

.img {
  position: relative;
  width: 90%;
}

.overview {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_info {
    @extend .flow-vert;
    transform: translateY(-2rem);
    align-self: flex-end;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1rem;
    width: 90%;

    p {
      max-width: 50ch;
    }
  }

  &_header {
    font-family: $font_serif;
    font-size: 1.5rem;
  }

  &_details {
    list-style: none;
    padding-left: 0;
    margin-top: 0.5rem;
  }

  &_img {
    position: relative;
    width: 90%;
    height: 90vmin;
    max-height: 50rem;
    object-fit: cover;
  }

  &__left {
    justify-content: flex-end;
  }
}

.smallPrint {
  font-style: italic;
  color: #555;
  font-size: 0.8rem;
}

@media (min-width: $media_tablet_landscape) {
  .overview {
    flex-direction: row;
    align-items: center;

    &_info {
      padding: 2rem;
      z-index: 1;
      transform: translateX(0);
      transform: translateY(-50%);
      position: absolute;
      right: 0;
      top: 50%;
      width: 50%;

      &__right {
        left: 0;
      }
    }

    &_img {
      width: 80%;
    }
  }
}
