.hidden {
  opacity: 0;
  transform: translateY(100px);
}

.fade_in {
  opacity: 1;
  transform: translateY(0);
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
