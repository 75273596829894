@import '../../CSS/base';

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  align-items: flex-end;
  z-index: 10;

  &_title {
    margin: 0;
    a {
      line-height: 0;
      margin: 0;
    }
  }

  &_logo {
    margin: 0;
    width: 12rem;
    translate: opacity 0.2s;
    &:hover {
      opacity: 0.65;
    }
  }

  &_nav {
    display: none;
  }
}

.hidden {
  opacity: 0;
}

.menu {
  position: relative;
  display: block;
  height: 1.9rem;
  width: 1.9rem;
  transition: all 0.2s;
  transform-origin: 50% 50%;
  transform: rotate(45deg);

  div {
    position: absolute;
    top: 50%;
    height: 2px;
    width: 100%;
    background-color: #000;
    border-radius: 20px;
    transform-origin: 50% 50%;
  }

  div:first-child {
    transform: rotate(45deg);
  }

  div:last-child {
    transform: rotate(-45deg);
  }
}

.rotate {
  height: 2rem;
  width: 2rem;
  transform: rotate(90deg);
}

.dropdown {
  z-index: 5;
  opacity: 0;
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.2s ease-in;
  background-color: #fff;
  width: 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropdown__visible {
  opacity: 1;
  width: 100vw;
}

@media (min-width: $media-tablet) {
  .header {
    align-items: flex-end;
    &_logo {
      width: 15rem;
    }
    &_nav {
      display: block;
      transform: translateX(-0.6rem);
    }
  }

  .menu {
    display: none;
  }
}
