@import './base';

body {
  letter-spacing: 1.6;
}

p,
li,
ul,
th {
  font-family: $font-main;
  line-height: 1.5;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
  font-family: $font-main;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
.h4,
h5,
h6 {
  font-family: $font-serif;
  margin: 0;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-family: $font-main;
  font-size: 1.3rem;
}

p {
  margin: 0;
  max-width: 50ch;
}

strong {
  font-weight: 800;
}
