@import '../../CSS/base';

.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &_header {
    font-family: $font-main;
    font-weight: normal;
    font-size: 1.5rem;
  }
}

.divider {
  height: 1px;
  background-color: white;
  opacity: 0.5;
}

.content_container {
  transition: max-height 0.4s ease-out;
  height: auto;
  max-height: 1px;
  overflow-y: hidden;
  margin: 0;
}

.content {
  padding-top: 1rem;
}
