.Loader,
.Loader:after {
  border-radius: 50%;
}
.Loader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 10px;
  border-top: 1.1em solid rgba(148, 133, 84, 0.4);
  border-right: 1.1em solid rgba(148, 133, 84, 0.4);
  border-bottom: 1.1em solid rgba(148, 133, 84, 0.4);
  border-left: 1.1em solid #b59952;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.Container {
  height: 100%;
  width: 100%;
}

.LoaderContainer {
  position: absolute;
  width: 10rem;
  height: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Icon {
  position: absolute;

  width: 6rem;
  height: 6rem;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  bottom: 0;
}
