@import '../../CSS/base';

.bookingbar {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 21;
  width: 100%;
  padding: 1.2rem 0;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2),
    0px -10px 10px rgba(0, 0, 0, 0.2);

  h2 {
    display: none;
    font-size: 1.5rem;
  }

  &_cost {
    margin: 0;
  }

  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_dates {
    display: none;

    .calendarLogo {
      height: 1.5rem;
    }
  }
}

@media (min-width: $media_tablet) {
  .bookingbar {
    &_dates {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: $color_light_beige;
      cursor: pointer;
      height: 2.4rem;
      img {
        height: 2rem;
      }

      & > * + * {
        margin: 0 0 0 1rem;
      }
    }
  }
}

.booknow {
  background-color: $color_red;
  border: 2px solid $color_red;
  color: white;
}

.hidden {
  opacity: 0;
  transform: translateY(50rem);

  transition: all 0.5s;
}

.show {
  opacity: 1;
  transform: translateY(0);
}



@media (min-width: $media_computer_small) {
  .bookingbar {
    h2 {
      display: block;
    }

    &_container {
      justify-content: flex-start;

      & > * + * {
        margin: 0 0 0 3rem;
      }
    }
  }

  .button {
    margin-left: auto;
    padding: 0.8rem 2rem;
  }
}
