@import '../../CSS/base';

.housePage {
  --gap: 6rem;
}

.container {
  --gap: 3rem;
}

.overview_details {
  margin-top: 2rem;
  h3 {
    margin-top: 1rem;
  }
  ul {
    margin-top: 0.5rem;
  }
}

.details {
  --gap: 2rem;
  &_overview {
    margin-bottom: 2rem;
  }
  &_split {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    div {
      padding-right: 0.5rem;
    }
  }

  &_terms {
    font-style: italic;
    font-size: 0.8rem;
    opacity: 0.8;
    max-width: 20ch;
    margin-top: 1.5rem;
  }

  &_icons {
    display: flex;
    align-items: center;
    img {
      margin-right: 1rem;
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

@media (min-width: $media_tablet_landscape) {
  .details {
    &_split {
      grid-template-columns: 1fr 1fr;
    }
  }
}
