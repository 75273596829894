@import './base';

.btn {
  padding: 1.5em 3em;
  border-radius: 1000px;
  text-align: center;
  transition: all 0.2s;
  display: inline-block;
  font-family: $font_main;
  font-weight: bold;
  flex-shrink: 0;
  border: 2px solid #000;
  background-color: transparent;

  &:hover {
    background-color: $color_dark_blue;
    border: 2px solid $color_dark_blue;
    color: #fff;
  }
  &:hover .btn-icon--invert {
      filter: invert(0);
  }
}

.btn-icon {
  width:1rem;
  transform: translateY(0.15rem);
  opacity: 0.7;
}

.btn-icon--invert {
  filter: invert(1);
}

.btn--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn--small {
  padding: 0.5em 2em;
  min-width: 8rem;
}

.btn--med {
  padding: 1em 2.3em;
}

.btn--dark {
  color: #fff;
  border: 2px solid #fff;

  &:hover {
    background-color: #fff;
    border: 2px solid #fff;
    color: #000;

    .btn-icon {
      filter: invert(1);
    }
  }
}

.section {
  padding: 3rem 0;
}

.inner-section {
  padding: 2rem;
}

.skip a {
  position: absolute;
  display: block;
  margin-top: 1em;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;

  font-size: 1.5rem;
}
.skip a:focus {
  text-align: center;
  position: sticky;
  width: 90vw;
  margin: auto;
  height: auto;
  opacity: 1;
}

.explore {
  --gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  margin: 6rem auto;
  text-align: center;

  &__main {
    font-family: $font-serif;
    font-size: 2.5rem;
    max-width: 25ch;
  }

  .btn {
    margin-top: 2rem;
  }
}

.internal-link {
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  transition: opacity 0.2s;
  font-weight: 500;
  &:hover {
    opacity: 0.5;
  }

  &--light {
    color: white;
  }
}

.arrow {
  height: 1.3rem;
  width: 3.6rem;
  cursor: pointer;
  &[aria-disabled='true'] {
    opacity: 0.2;
  }
}

.arrow--sm {
  height: 0.7rem;
  width: 2.5rem;
}

@media (min-width: $media_computer_large) {
  .explore-section {
    min-height: auto;
    margin: 13rem 2rem;

    &_main {
      font-size: 3rem;
    }
  }
}
